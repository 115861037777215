import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  Action,
  SET_SHOP_PREFERENCES,
  ICollectPoint,
  SET_INITIAL_DELIVERY_POINTS,
  SET_FETCHING,
  SET_MAP,
  SET_SELECTED_POINT,
  SET_USER_LOCATION,
  SET_FILTERS,
  SET_CENTER,
  Filters,
  Preferences,
  SET_API_ERROR,
  SET_PERMISSION_ERROR,
  State,
  SET_AVAILABLE_DELIVERY_POINTS,
} from "../types";
import { Map, LatLng } from "leaflet";
import { getIFrameParamValue } from "../utils/iframe";
import { getI18n } from "react-i18next";
import { oneLineTrim } from "common-tags";
import { filterPoints } from "../utils/geo";

export function setIsFetching(isFetching: boolean): Action {
  return {
    type: SET_FETCHING,
    payload: isFetching,
  };
}

export function setShopPreferences(preferences: Preferences): Action {
  return {
    type: SET_SHOP_PREFERENCES,
    payload: preferences,
  };
}

export function setInitialPointsList(
  initialPointsList: ICollectPoint[]
): Action {
  return {
    type: SET_INITIAL_DELIVERY_POINTS,
    payload: initialPointsList,
  };
}

export function setAvailablePointsList(
  availablePointsList: ICollectPoint[]
): Action {
  return {
    type: SET_AVAILABLE_DELIVERY_POINTS,
    payload: availablePointsList,
  };
}

export function setSelectedPoint(collectPoint: ICollectPoint | null): Action {
  return {
    type: SET_SELECTED_POINT,
    payload: collectPoint,
  };
}

export function setMapInstance(map: Map): Action {
  return {
    type: SET_MAP,
    payload: map,
  };
}

export function setUserLocation(userLocation: LatLng): Action {
  return {
    type: SET_USER_LOCATION,
    payload: userLocation,
  };
}

export function setCenter(center: LatLng): Action {
  return {
    type: SET_CENTER,
    payload: center,
  };
}

export function setApiError(error: string): Action {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setPermissionError(error: GeolocationPositionError): Action {
  return {
    type: SET_PERMISSION_ERROR,
    payload: error,
  };
}

export function setFilters(activeFilters: Filters): Action {
  return {
    type: SET_FILTERS,
    payload: activeFilters,
  };
}

export const fetchPointsList = (
  coords?: any | undefined
): ThunkAction<Promise<void>, State, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState
  ): Promise<void> => {
    const state = getState();
    try {
      const shop = getIFrameParamValue("shop");
      const token = getIFrameParamValue("token");
      const orderId = getIFrameParamValue("orderId");
      const connectorType = getIFrameParamValue("connectorType");
      const backendUrl = getIFrameParamValue("backendUrl") || "https://xconnector.app";

      const searchParam = state.filters.searchParam
        ? `&search=${state.filters.searchParam}`
        : ``;

      if (coords) {
        let url = oneLineTrim(`
        ${backendUrl}/collect-points
        ?shop=${shop}
        &token=${token}
        &orderId=${orderId}
        &connectorType=${connectorType}
        &saturdayOpen=${state.filters.saturdayOpen}
        &sundayOpen=${state.filters.sundayOpen}
        &payByCard=${state.filters.payByCard}
        &wheelChairAccessible=${state.filters.wheelChairAccessible}
        &availableCapacity=${state.filters.availableCapacity}
        &latitude=${coords.latitude}
        &longitude=${coords.longitude}
        &distance=${coords.zoom}
        ${searchParam}
      `);

        const response = await (await fetch(url)).json();

        dispatch(setInitialPointsList(response));

        return;
      }
      dispatch(setIsFetching(true));

      await navigator.geolocation.getCurrentPosition(
          async function (position) {
            const DISTANCE = 100;
            let url = oneLineTrim(`
        ${backendUrl}/collect-points
        ?shop=${shop}
        &token=${token}
        &orderId=${orderId}
        &connectorType=${connectorType}
        &saturdayOpen=${state.filters.saturdayOpen}
        &sundayOpen=${state.filters.sundayOpen}
        &payByCard=${state.filters.payByCard}
        &wheelChairAccessible=${state.filters.wheelChairAccessible}
        &availableCapacity=${state.filters.availableCapacity}
        &latitude=${position.coords.latitude}
        &longitude=${position.coords.longitude}
        &distance=${DISTANCE}
        ${searchParam}
      `);

          dispatch(
            setUserLocation(
              new LatLng(position.coords.latitude, position.coords.longitude)
            )
          );
          const response = await (await fetch(url)).json();

          dispatch(setInitialPointsList(response));
          dispatch(applyFilters());
          dispatch(setIsFetching(false));
        },
        async function (err) {
          let url = oneLineTrim(`
        ${backendUrl}/collect-points
        ?shop=${shop}
        &token=${token}
        &orderId=${orderId}
        &connectorType=${connectorType}
        &saturdayOpen=${state.filters.saturdayOpen}
        &sundayOpen=${state.filters.sundayOpen}
        &payByCard=${state.filters.payByCard}
        &wheelChairAccessible=${state.filters.wheelChairAccessible}
        &availableCapacity=${state.filters.availableCapacity}
        &latitude=52.519325
        &longitude=13.392709
        &distance=200
        ${searchParam}
      `);

          const response = await (await fetch(url)).json();

          dispatch(setInitialPointsList(response));
          dispatch(applyFilters());
          dispatch(setPermissionError(err));

          dispatch(setIsFetching(false));
        }
      );
    } catch (err) {
      console.error(err);
      dispatch(setApiError("There was an error! Please, try again later!"));
      dispatch(setIsFetching(false));
    }
  };
};

export const applyFilters = () => {
  return async (
    dispatch: (arg0: Action) => void,
    getState: () => any
  ): Promise<void> => {
    const state = getState();

    if (!state.filters.searchParam) {
      const filteredPoints = filterPoints(
        state.filters,
        state.initialPointsList
      );
      dispatch(setAvailablePointsList(filteredPoints));
    } else {
      try {
        dispatch(setIsFetching(true));

        const shop = getIFrameParamValue("shop");
        const token = getIFrameParamValue("token");
        const orderId = getIFrameParamValue("orderId");
        const connectorType = getIFrameParamValue("connectorType");
        const backendUrl = getIFrameParamValue("backendUrl") || "https://xconnector.app";

        const searchParam = state.filters.searchParam
          ? `&search=${state.filters.searchParam}`
          : ``;

        let url = oneLineTrim(`
            ${backendUrl}/collect-points
            ?shop=${shop}
            &token=${token}
            &orderId=${orderId}
            &connectorType=${connectorType}
            &saturdayOpen=${state.filters.saturdayOpen}
            &sundayOpen=${state.filters.sundayOpen}
            &payByCard=${state.filters.payByCard}
            &wheelChairAccessible=${state.filters.wheelChairAccessible}
            &availableCapacity=${state.filters.availableCapacity}
            &search=${state.filters.searchParam}
          `);
        const response = await (await fetch(url)).json();
        dispatch(setInitialPointsList(response));
        dispatch(setAvailablePointsList(response));
        dispatch(setIsFetching(false));
      } catch (err) {
        console.error(err);
        dispatch(setApiError("There was an error! Please, try again later!"));
        dispatch(setIsFetching(false));
      }
    }
  };
};

export const fetchPreferences = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const shop = getIFrameParamValue("shop");
      const token = getIFrameParamValue("token");
      const orderId = getIFrameParamValue("orderId");
      const connectorType = getIFrameParamValue("connectorType");
      const backendUrl = getIFrameParamValue("backendUrl") || "https://xconnector.app";

      const url = `${backendUrl}/collect-points/preferences?shop=${shop}&token=${token}&orderId=${orderId}&connectorType=${connectorType}`;
      const response: Preferences = await (await fetch(url)).json();

      const i18n = getI18n();
      i18n.changeLanguage(response.lang);
      dispatch(setShopPreferences(response));
    } catch (err) {
      console.error(err);
      dispatch(setApiError("There was an error! Please, try again later!"));
    }
  };
};
