import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setSelectedPoint } from "../../store/actions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Preferences, State } from "../../types";
type Props = {
  showBackButton: boolean;
};

export const Header: React.FC<Props> = ({ showBackButton }) => {
  const { t } = useTranslation();
  const dispatch: React.Dispatch<any> = useDispatch();
  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <header style={{ borderBottom: "1px solid #f3f3f3" }}>
      <Box display="flex" mx={1}>
        {showBackButton ? (
          <Box width="80%" display="flex">
            <Button
              onClick={() => dispatch(setSelectedPoint(null))}
              style={{ textTransform: "none" }}
            >
              <Typography
                variant="subtitle1"
                noWrap
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ArrowBackIosIcon fontSize="small"></ArrowBackIosIcon>

                {t("Back to point list")}
              </Typography>
            </Button>
          </Box>
        ) : (
          !isMobile && (
            <Box display="flex" alignItems="center">
              <Typography variant="h6" noWrap>
                {t("Pick up point selection")}
              </Typography>
            </Box>
          )
        )}

        <Box
          flexShrink={1}
          display="flex"
          width="100%"
          justifyContent="space-between"
        >
          <Box alignSelf="center" width="100%" style={{ textAlign: "center" }}>
            <img
              src={preferences ? preferences.logo : "./logo.png"}
              alt="Logo"
              height="35px"
            />
          </Box>
          <Box>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() =>
                window.parent.postMessage(
                  JSON.stringify({ type: "close", data: true }),
                  "*"
                )
              }
              style={{ float: "right" }}
              size="large"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </header>
  );
};
