import L, { LatLngTuple } from "leaflet";
import {
  State,
  DELETE_DELIVERY_POINT,
  Action,
  ICollectPoint,
  SET_INITIAL_DELIVERY_POINTS,
  SET_AVAILABLE_DELIVERY_POINTS,
  SET_FETCHING,
  SET_SELECTED_POINT,
  SET_MAP,
  SET_USER_LOCATION,
  SET_FILTERS,
  SET_CENTER,
  SET_SHOP_PREFERENCES,
  SET_API_ERROR,
  SET_PERMISSION_ERROR,
} from "../types";
import {
  computeDistance,
  filterPoints,
  sortPointsByDistance,
} from "../utils/geo";

const initialState: State = {
  permissionError: null,
  preferences: null,
  initialPointsList: [],
  availablePoints: [],
  isFetching: false,
  apiError: null,
  filters: {
    saturdayOpen: false,
    sundayOpen: false,
    payByCard: false,
    wheelChairAccessible: false,
    availableCapacity: false,
    searchParam: null,
  },
  userLocation: null,
  selectedPoint: null,
  mapInstance: null,
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_SHOP_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
      };
    case SET_SELECTED_POINT:
      if (!action.payload) {
        return {
          ...state,
          selectedPoint: null,
        };
      }
      if (state.mapInstance) {
        const center: LatLngTuple = [
          parseFloat(action.payload.latitude),
          parseFloat(action.payload.longitude),
        ];
        state.mapInstance.setView(center, 18);
      }

      if (state.filters.searchParam) {
        return {
          ...state,
          selectedPoint: action.payload,
        };
      }

      let pointsNextToCenter = [];
      const allPoints = sortPointsByDistance(
        state.initialPointsList.map((item: ICollectPoint) => {
          return {
            ...item,
            selected: false,
            distance: computeDistance(
              new L.LatLng(
                parseFloat(action.payload!.latitude),
                parseFloat(action.payload!.longitude)
              ),
              new L.LatLng(
                parseFloat(item.latitude),
                parseFloat(item.longitude)
              )
            ),
          };
        })
      );

      for (let i = 0; i < allPoints.length; i++) {
        if (parseFloat(allPoints[i]?.distance) > 10) {
          break;
        }
        pointsNextToCenter.push(allPoints[i]);
      }

      return {
        ...state,
        selectedPoint: action.payload,
        availablePoints: filterPoints(state.filters, pointsNextToCenter),
      };
    case SET_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case SET_INITIAL_DELIVERY_POINTS:
      // const sortedPoints = action.payload.map((item: ICollectPoint) => {
      //   //sortPointsByDistance(
      //   return { ...item, selected: false, distance: "" };
      // });
      // // );

      let points = [];
      let selectedPointLocation = state?.selectedPoint
        ? new L.LatLng(
            parseFloat(state?.selectedPoint?.latitude),
            parseFloat(state?.selectedPoint?.longitude)
          )
        : null;

      const pointsSortedByDistance = sortPointsByDistance(
        action.payload.map((item: ICollectPoint) => {
          return {
            ...item,
            selected: false,
            distance:
              selectedPointLocation || state.userLocation
                ? computeDistance(
                    selectedPointLocation ?? state.userLocation!,
                    new L.LatLng(
                      parseFloat(item.latitude),
                      parseFloat(item.longitude)
                    )
                  )
                : "",
          };
        })
      );
      const MAX_POINTS = 100;

      return {
        ...state,
        initialPointsList: pointsSortedByDistance,
        availablePoints:
          pointsSortedByDistance.length > MAX_POINTS
            ? Array(MAX_POINTS)
                .fill(0)
                .map((e, i) => pointsSortedByDistance[i])
            : pointsSortedByDistance,
      };

    case SET_AVAILABLE_DELIVERY_POINTS:
      if (state.filters.searchParam) {
        return {
          ...state,
          availablePoints: action.payload,
        };
      }

      // let points = [];
      // let selectedPointLocation = state?.selectedPoint
      //   ? new L.LatLng(
      //       parseFloat(state?.selectedPoint?.latitude),
      //       parseFloat(state?.selectedPoint?.longitude)
      //     )
      //   : null;

      // const pointsSortedByDistance = sortPointsByDistance(
      //   action.payload.map((item: ICollectPoint) => {
      //     return {
      //       ...item,
      //       selected: false,
      //       distance:
      //         selectedPointLocation || state.userLocation
      //           ? computeDistance(
      //               selectedPointLocation ?? state.userLocation!,
      //               new L.LatLng(
      //                 parseFloat(item.latitude),
      //                 parseFloat(item.longitude)
      //               )
      //             )
      //           : "",
      //     };
      //   })
      // );

      // for (let i = 0; i < pointsSortedByDistance.length; i++) {
      //   if (parseFloat(pointsSortedByDistance[i]?.distance) > 10) {
      //     break;
      //   }
      //   points.push(pointsSortedByDistance[i]);
      // }

      return {
        ...state,
        availablePoints: [],
        // selectedPointLocation || state.userLocation ? points : [],
      };
    case DELETE_DELIVERY_POINT:
      const updatedPoints: ICollectPoint[] = state.initialPointsList.filter(
        (point) => point.id !== action.payload.id
      );
      return {
        ...state,
        initialPointsList: updatedPoints,
      };
    case SET_MAP:
      if (state.selectedPoint && state.selectedPoint.id !== "") {
        const center: LatLngTuple = [
          parseFloat(state.selectedPoint.latitude),
          parseFloat(state.selectedPoint.longitude),
        ];
        action.payload.options.center = center;
        action.payload.options.zoom = 17;
        state.mapInstance = action.payload;
        state.mapInstance?.setView(center, 17);
      }
      return {
        ...state,
        mapInstance: action.payload,
      };
    case SET_USER_LOCATION:
      const pointsWithDistance = sortPointsByDistance(
        state.initialPointsList.map((item) => ({
          ...item,
          distance: computeDistance(
            action.payload,
            new L.LatLng(parseFloat(item.latitude), parseFloat(item.longitude))
          ),
        }))
      );

      return {
        ...state,
        userLocation: action.payload,
        initialPointsList: pointsWithDistance,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };

    case SET_CENTER:
      if (state.mapInstance) {
        state.mapInstance?.setView(action.payload, 18);
      }
      return {
        ...state,
        selectedPoint: null,
      };
    case SET_API_ERROR:
      return {
        ...state,
        apiError: action.payload,
      };
    case SET_PERMISSION_ERROR:
      return {
        ...state,
        permissionError: action.payload,
      };
  }
  return state;
};

export default reducer;
