import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Map } from "./components/Map/Map";
import { State, ICollectPoint, Preferences } from "./types";
import {
  fetchPointsList,
  fetchPreferences,
  setCenter,
  setSelectedPoint,
} from "./store/actions";
import { ThunkDispatch } from "redux-thunk";
import { useEffect } from "react";
import { Header } from "./components/Header/Header";
import PointsList from "./components/Points/PointsList";
import { PointsSearch } from "./components/Points/PointsSearch";
import {
  Button,
  createTheme,
  Snackbar,
  ThemeProvider,
  Theme,
  Divider,
  StyledEngineProvider,
  Typography,
  adaptV4Theme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import "./App.css";
import { MapFooter } from "./components/MapFooter/MapFooter";
import { PointsFilters } from "./components/Points/PointsFilters";
import Box from "@mui/material/Box";
import { Alert } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MapIcon from "@mui/icons-material/Map";
import "./translations";
import { LatLng } from "leaflet";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// if (process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const App: React.FC = () => {
  const { t } = useTranslation();
  const theme = createTheme(
    adaptV4Theme({
      props: {
        // Name of the component
        MuiButtonBase: {
          // The properties to apply
          disableRipple: true, // No more ripple, on the whole application!
        },
      },
    })
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const selectedPoint: ICollectPoint | null = useSelector(
    (state: State) => state.selectedPoint,
    shallowEqual
  );

  const error: string | null = useSelector(
    (state: State) => state.apiError,
    shallowEqual
  );

  const initialPoints: ICollectPoint[] | null = useSelector(
    (state: State) => state.initialPointsList,
    shallowEqual
  );

  const availablePoints: ICollectPoint[] | null = useSelector(
    (state: State) => state.availablePoints,
    shallowEqual
  );

  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );

  const isFetching = useSelector(
    (state: State) => state.isFetching,
    shallowEqual
  );

  const permissionError = useSelector(
    (state: State) => state.permissionError,
    shallowEqual
  );

  const thunkDispatch: ThunkDispatch<any, any, any> = useDispatch();
  const recenterToUser = React.useCallback(
    (userLocation: LatLng) => thunkDispatch(setCenter(userLocation)),
    [thunkDispatch]
  );

  const userLocation = useSelector(
    (state: State) => state.userLocation,
    shallowEqual
  );

  const [mapVisible, setMapVisible] = React.useState(false);

  const searchParam = useSelector(
    (state: State) => state.filters.searchParam,
    shallowEqual
  );

  React.useEffect(() => {
    if (searchParam?.length && mapVisible) setMapVisible(false);
  }, [searchParam]);

  useEffect(() => {
    thunkDispatch(fetchPreferences());
    thunkDispatch(fetchPointsList());
  }, [thunkDispatch]);

  useEffect(() => {
    if (permissionError && isMobile) setMapVisible(true);
  }, [permissionError]);

  useEffect(() => {
    if (
      !selectedPoint &&
      availablePoints.length &&
      !searchParam?.length &&
      !mapVisible
    )
      thunkDispatch(setSelectedPoint(userLocation ? availablePoints[0] : null));
  }, [isFetching, availablePoints]);

  useEffect(() => {
    if (!selectedPoint && !isMobile)
      thunkDispatch(setSelectedPoint(userLocation ? availablePoints[0] : null));
  }, [availablePoints, isMobile]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {error ? (
          <Snackbar open>
            <Alert severity="error">
              {t(error || "There was an error! Please, try again later!")}
            </Alert>
          </Snackbar>
        ) : null}
        <Box
          display="flex"
          flexDirection="column"
          height="100vh"
          maxHeight={"-webkit-fill-available"}
          className="appRoot"
          visibility={preferences ? "visible" : "hidden"}
        >
          <Box>
            <Header
              showBackButton={isMobile && selectedPoint ? true : false}
            ></Header>
          </Box>
          <Box
            display="flex"
            height={`91%`}
            flexDirection={mapVisible ? "column" : "row"}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{
                maxWidth: isMobile ? "auto" : "500px",
                ...(!mapVisible
                  ? { flex: isMobile ? "auto" : "0 0 500px" }
                  : {}),
                display:
                  !isMobile || (isMobile && !selectedPoint)
                    ? "inherit"
                    : "none",
              }}
              mx={1}
              mt={1}
            >
              <Box pb={1} display="flex">
                <PointsSearch />
                {isMobile && (
                  <IconButton
                    disabled={isFetching}
                    aria-label="delete"
                    onClick={() => {
                      setMapVisible(!mapVisible);
                      mapVisible && recenterToUser(userLocation!);
                    }}
                  >
                    {mapVisible ? <FormatListBulletedIcon /> : <MapIcon />}
                  </IconButton>
                )}
              </Box>
              <Box pb={0.25}>
                <PointsFilters />
              </Box>
              {!mapVisible && (
                <PointsList
                  points={availablePoints}
                  selectedPoint={selectedPoint}
                />
              )}
            </Box>

            <Box
              flexDirection="column"
              flexGrow={1}
              p={1}
              display={
                !isMobile || mapVisible || (isMobile && selectedPoint)
                  ? "flex"
                  : "none"
              }
            >
              <Map />

              {selectedPoint ? (
                <Box
                  flexGrow={1}
                  flexBasis={250}
                  style={{
                    width: "100%",
                  }}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  mt={1}
                >
                  <MapFooter selectedPoint={selectedPoint} />
                  <Divider
                    sx={{
                      width: "100%",
                    }}
                  />
                  <Box
                    display="flex"
                    alignSelf="stretch"
                    justifyContent="center"
                    pt={2}
                  >
                    <Button
                      fullWidth={isMobile}
                      variant="contained"
                      className="choosePointButton"
                      onClick={() => {
                        window.parent.postMessage(
                          JSON.stringify({
                            type: "result",
                            data: selectedPoint,
                          }),
                          "*"
                        );
                        window.parent.postMessage(
                          JSON.stringify({ type: "close", data: true }),
                          "*"
                        );
                      }}
                    >
                      {t("Choose this pick up point")}
                    </Button>
                  </Box>
                </Box>
              ) : null}
              {!selectedPoint && !isFetching && !mapVisible && (
                <Box
                  mt={2}
                  alignSelf="center"
                  width={isMobile ? "100%" : "50%"}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6">
                    {t("Select an available pick-up point")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
