import React, { forwardRef, VFC } from "react";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";

import { useTranslation } from "react-i18next";
import { Filters, Preferences, State } from "../../types";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setFilters, fetchPointsList } from "../../store/actions";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccessibleIcon from "@mui/icons-material/Accessible";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import { Tooltip, TooltipProps } from "@mui/material";
import { computeDistance } from "../../utils/geo";

type TooltipToggleButtonProps = ToggleButtonProps & {
  TooltipProps: Omit<TooltipProps, "children">;
};

// Catch props and forward to ToggleButton
const TooltipToggleButton: VFC<TooltipToggleButtonProps> = forwardRef(
  ({ TooltipProps, ...props }, ref) => {
    return (
      <Tooltip {...TooltipProps}>
        <ToggleButton ref={ref} {...props} />
      </Tooltip>
    );
  }
);

const displayFilters = (preferences: Preferences) => {
  return (
    preferences.showAvailabilityLabel ||
    preferences.showSaturdayOpenFilter ||
    preferences.showSundayOpenFilter ||
    preferences.showPayByCardFilter ||
    preferences.showWheelChairAccessibleFilter ||
    preferences.showAvailableCapacityFilter
  );
};
export const PointsFilters = () => {
  const { t } = useTranslation();
  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );

  const [localFilters, setLocalFilters] = React.useState<string[]>(() => []);
  const storeFilters: Filters = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const mapInstance = useSelector(
    (state: State) => state.mapInstance,
    shallowEqual
  );

  const dispatch: React.Dispatch<any> = useDispatch();
  const setFiltersAction = React.useCallback(
    (filters: Filters) => dispatch(setFilters(filters)),
    [dispatch]
  );
  return preferences && !displayFilters(preferences) ? null : (
    <ToggleButtonGroup
      sx={{
        mb: 0.5,
      }}
      size="small"
      fullWidth
      aria-label="text formatting"
      value={localFilters}
      onChange={(_, newFilters: string[] | null) => {
        const upcoming = newFilters || [];
        setLocalFilters(upcoming);
        const filters = {
          saturdayOpen: upcoming.includes("saturdayFilter"),
          sundayOpen: upcoming.includes("sundayFilter"),
          wheelChairAccessible: upcoming.includes("accesibleFilter"),
          payByCard: upcoming.includes("creditCardFilter"),
          availableCapacity: upcoming.includes("capacityFilter"),

          searchParam: storeFilters.searchParam,
        } as Filters;
        dispatch(setFiltersAction(filters));
        dispatch(
          fetchPointsList({
            zoom: computeDistance(
              mapInstance?.getBounds().getNorthEast()!,
              mapInstance?.getBounds().getSouthWest()!
            ),
            latitude: mapInstance?.getCenter().lat,
            longitude: mapInstance?.getCenter().lng,
          })
        );
        if (document.querySelector("#available-points")) {
          document.querySelector("#available-points")!.scrollTop = 0;
        }
      }}
    >
      {preferences?.showSaturdayOpenFilter && (
        <TooltipToggleButton
          size="small"
          value="saturdayFilter"
          aria-label="sunday"
          TooltipProps={{
            title: t("Open Saturday") as string,
          }}
        >
          <EventAvailableIcon />
          {t("Saturday").slice(0, 3)}
        </TooltipToggleButton>
      )}
      {preferences?.showSundayOpenFilter && (
        <TooltipToggleButton
          value="sundayFilter"
          aria-label="sunday"
          TooltipProps={{
            title: t("Open Sunday") as string,
          }}
        >
          <EventAvailableIcon />
          {t("Sunday").slice(0, 3)}
        </TooltipToggleButton>
      )}
      {preferences?.showPayByCardFilter && (
        <TooltipToggleButton
          value="creditCardFilter"
          aria-label="creditCardFilter"
          TooltipProps={{
            title: t("Possibility to pay by card") as string,
          }}
        >
          <CreditCardIcon />
        </TooltipToggleButton>
      )}
      {preferences?.showWheelChairAccessibleFilter && (
        <TooltipToggleButton
          value="accesibleFilter"
          aria-label="accesibleFilter"
          TooltipProps={{
            title: t("Wheelchair access") as string,
          }}
        >
          <AccessibleIcon />
        </TooltipToggleButton>
      )}
      {preferences?.showAvailableCapacityFilter && (
        <TooltipToggleButton
          value="capacityFilter"
          aria-label="capacityFilter"
          TooltipProps={{
            title: t("Pick-up point available") as string,
          }}
        >
          <AllInboxIcon />
        </TooltipToggleButton>
      )}
    </ToggleButtonGroup>
  );
};
