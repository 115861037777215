export const collectPointSvg = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g
      id="Badge_harta"
      data-name="Badge harta"
      transform="translate(-462 -319)"
    >
      <g
        id="Ellipse_39"
        data-name="Ellipse 39"
        transform="translate(462 319)"
        fill="#34a061"
        stroke="#fff"
        stroke-width="1"
      >
        <circle cx="16" cy="16" r="16" stroke="none" />
        <circle cx="16" cy="16" r="15.5" fill="none" />
      </g>
      <g id="icon_easybox_simplu" transform="translate(469.011 326.011)">
        <g
          id="icon_easybox_simplu-2"
          data-name="icon_easybox_simplu"
          transform="translate(0)"
        >
          <g id="Group_2781" data-name="Group 2781">
            <g id="Group_2780" data-name="Group 2780">
              <path
                id="Path_1509"
                data-name="Path 1509"
                d="M45.245,6.2,37.471,9.269,34.5,8.052,42.392,5Z"
                transform="translate(-33.497 -5)"
                fill="#fff"
              />
              <path
                id="Path_1510"
                data-name="Path 1510"
                d="M176.614,60.3l-7.575,3.089L165.5,61.945l7.781-3.045Z"
                transform="translate(-159.687 -56.913)"
                fill="#fff"
              />
              <path
                id="Path_1511"
                data-name="Path 1511"
                d="M288.022,125.7v10.406L280.2,139.3V128.8Z"
                transform="translate(-270.159 -121.269)"
                fill="#fff"
              />
              <path
                id="Path_1512"
                data-name="Path 1512"
                d="M15.87,118.406V128.9L7.3,125.575V114.9l3.089,1.261V118.8a.05.05,0,0,0,.026.048.067.067,0,0,0,.048.007l.774-.262.756.866c.007.015.022.018.041.018h.022a.055.055,0,0,0,.037-.052L12.2,116.9Z"
                transform="translate(-7.3 -110.867)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>`;

export const selectedPointSvg = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="55"
    viewBox="0 0 38 55"
  >
    <g id="Pin_harta" data-name="Pin harta" transform="translate(4274 2977)">
      <path
        id="Path_1508"
        data-name="Path 1508"
        d="M19,0A18.885,18.885,0,0,1,38,18.767C38.036,33.691,19,55,19,55S.258,33.217,0,18.767A18.813,18.813,0,0,1,19,0Z"
        transform="translate(-4274 -2977)"
        fill="#34a061"
      />
      <g id="icon_easybox_simplu" transform="translate(-4266.5 -2969)">
        <g
          id="icon_easybox_simplu-2"
          data-name="icon_easybox_simplu"
          transform="translate(0)"
        >
          <g id="Group_2781" data-name="Group 2781">
            <g id="Group_2780" data-name="Group 2780">
              <path
                id="Path_1509"
                data-name="Path 1509"
                d="M48.208,6.533,38.29,10.445,34.5,8.894,44.568,5Z"
                transform="translate(-33.221 -5)"
                fill="#fff"
              />
              <path
                id="Path_1510"
                data-name="Path 1510"
                d="M179.678,60.692l-9.664,3.941L165.5,62.784l9.927-3.884Z"
                transform="translate(-158.084 -56.365)"
                fill="#fff"
              />
              <path
                id="Path_1511"
                data-name="Path 1511"
                d="M290.179,125.7v13.275l-9.979,4.072V129.66Z"
                transform="translate(-267.39 -120.048)"
                fill="#fff"
              />
              <path
                id="Path_1512"
                data-name="Path 1512"
                d="M18.233,119.372v13.383L7.3,128.518V114.9l3.941,1.608v3.367a.064.064,0,0,0,.033.061.085.085,0,0,0,.061.009l.988-.334.964,1.105c.009.019.028.024.052.024h.028a.071.071,0,0,0,.047-.066l.136-3.226Z"
                transform="translate(-7.3 -109.755)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>`;
