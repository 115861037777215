import * as React from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  Popup,
  useMapEvents,
  useMapEvent,
} from "react-leaflet";
import { ICollectPoint, State } from "../../types";
import { LatLngTuple } from "leaflet";
import * as Leaflet from "leaflet";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setMapInstance,
  setSelectedPoint,
  setApiError,
  fetchPointsList,
} from "../../store/actions";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import PixiOverlay from "react-leaflet-pixi-overlay";
import CircularProgress from "@mui/material/CircularProgress";
import { collectPointSvg, selectedPointSvg } from "./markers";
import { computeDistance } from "../../utils/geo";

type Props = {};

export const Map: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const mapInstance: Leaflet.Map | null = useSelector(
    (state: State) => state.mapInstance,
    shallowEqual
  );
  const searchParam = useSelector(
    (state: State) => state.filters.searchParam,
    shallowEqual
  );
  const permissionError = useSelector(
    (state: State) => state.permissionError,
    shallowEqual
  );

  const selectedPoint: ICollectPoint | null = useSelector(
    (state: State) => state.selectedPoint,
    shallowEqual
  );

  const initialPointsList: any = useSelector(
    (state: State) => state.initialPointsList,
    shallowEqual
  );

  const [initialCenter] = useState<LatLngTuple>([45.792784, 24.152069]);
  const [initialZoom] = useState<number>(8);

  const [mapIsLoading, setMapIsLoading] = useState<boolean>(false);
  const userLocation = useSelector(
    (state: State) => state.userLocation,
    shallowEqual
  );

  useEffect(() => {
    if (userLocation && mapIsLoading) {
      mapInstance?.flyTo(userLocation, 17);
      setMapIsLoading(false);
    }

    if (permissionError && mapIsLoading) {
      mapInstance?.setView([52.519325, 13.392709], 4);
      setMapIsLoading(false);
    }
  }, [userLocation, permissionError]);

  function LocationMarker() {
    const [position, setPosition] = useState<Leaflet.LatLngExpression | null>(
      null
    );

    useEffect(() => {
      if (mapInstance) {
        mapInstance.invalidateSize();
      }
    }, [selectedPoint, mapInstance]);

    const getPoints = () => {
      return (
        !searchParam?.length &&
        dispatch(
          fetchPointsList({
            latitude: map?.getCenter().lat,
            longitude: map?.getCenter().lng,
            zoom: computeDistance(
              map?.getBounds().getNorthEast(),
              map?.getBounds().getSouthWest()
            ),
          })
        )
      );
    };
    const map = useMapEvents({
      zoomend(e) {
        // setTimeout() is here because there are issues with the library, we cannot detect if zoomend is programmatic or initiated by user
        // https://github.com/Leaflet/Leaflet/pull/6929 ...yay, library issues
        // I can go on a whoel rant here about redux.
        // fuck it, setTimeout() sends the code to the bottom of the execution stack
        // it works...
        setTimeout((e) => getPoints(), 0);
      },
      dragend(e) {
        getPoints();
        map.invalidateSize(true);
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>{t("You are here")}</Popup>
      </Marker>
    );
  }

  const availablePoints = useSelector(
    (state: State) => state.availablePoints,
    shallowEqual
  );

  const selectPoint = React.useCallback(
    (selectedPoint: ICollectPoint) => dispatch(setSelectedPoint(selectedPoint)),
    [dispatch]
  );
  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (mapInstance) {
        mapInstance.invalidateSize(true);
      } else {
        dispatch(setApiError("There was an error! Please, try again later!"));
      }
      setMapIsLoading(false);
    }, 10000);
    return () => {
      clearTimeout(timer1);
    };
  }, [mapInstance, dispatch]);

  return (
    <Box
      p={0}
      flexShrink={1}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: mapIsLoading ? "flex" : "none",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        <Typography variant="subtitle1" noWrap>
          {!permissionError
            ? t("Please allow access to your location.")
            : "Loading..."}
        </Typography>
      </Box>
      <Box
        sx={{
          display: mapIsLoading ? "hidden" : "initial",
        }}
      >
        <MapContainer
          id="map"
          className="map hidden-map"
          style={{
            height: "100%",
          }}
          center={initialCenter}
          preferCanvas
          zoom={initialZoom}
          whenCreated={(map) => {
            if (!mapInstance) {
              map.locate();
              setMapIsLoading(true);

              dispatch(setMapInstance(map));
            }
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://xconnector.ro">xConnector</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <PixiOverlay
            markers={React.useMemo(
              () =>
                initialPointsList.map(
                  (collectPoint: ICollectPoint, idx: any) => ({
                    id: idx,
                    iconId:
                      selectedPoint?.id !== collectPoint.id
                        ? "selectedIcon"
                        : "icon",
                    customIcon:
                      selectedPoint?.id !== collectPoint.id
                        ? collectPointSvg
                        : selectedPointSvg,
                    onClick: () => {
                      if (
                        !searchParam &&
                        availablePoints.length &&
                        document.querySelector("#available-points")
                      ) {
                        document.querySelector(
                          "#available-points"
                        )!.scrollTop = 0;
                      }
                      selectPoint(collectPoint);
                    },
                    position: [
                      Number(collectPoint.latitude),
                      Number(collectPoint.longitude),
                    ],
                  })
                ),
              [selectedPoint, initialPointsList]
            )}
          />

          <LocationMarker />
        </MapContainer>
      </Box>
    </Box>
  );
};
