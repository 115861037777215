import * as React from "react";
import { ICollectPoint, Preferences, State } from "../../types";
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ScheduleItem } from "./ScheduleItem";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccessibleIcon from "@mui/icons-material/Accessible";
type Props = {
  selectedPoint: ICollectPoint | null;
};
const isPointAvailable = (selectedPoint: ICollectPoint) => {
  return selectedPoint.mondaySchedule ||
    selectedPoint.thursdaySchedule ||
    selectedPoint.wednesdaySchedule ||
    selectedPoint.thursdaySchedule ||
    selectedPoint.fridaySchedule ||
    selectedPoint.saturdaySchedule ||
    selectedPoint.sundaySchedule ||
    selectedPoint.availableCapacity
    ? true
    : false;
};
export const MapFooter: React.FC<Props> = ({ selectedPoint }) => {
  const { t } = useTranslation();
  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const lessThanDesktop = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <React.Fragment>
      {selectedPoint ? (
        <Grid
          container
          spacing={2}
          sx={{
            p: 1,
            maxHeight: isMobile ? "200px" : "initial",
            overflowY: isMobile ? "scroll" : "initial",
            // justifyContent: "space-between",
            "-webkit-mask-image": isMobile
              ? "linear-gradient(to bottom, black 75%, transparent 100%)"
              : "initial",
          }}
        >
          <Grid item spacing={1} lg={7} container>
            <Grid item xs={2} className="imageContainer">
              {/* <Box display="flex" justifyContent="center"> */}
              <img
                width="100%"
                height="auto"
                src={"./shop-thumbnail.jpg"}
                alt="Logo"
                className="thumbnailImage"
              />
              {/* </Box> */}
            </Grid>
            <Grid
              item
              md={9}
              xs={10}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1" fontWeight={700}>
                {selectedPoint.address1}
              </Typography>
              <Typography variant="body1">{selectedPoint.name}</Typography>

              <Typography variant="body2">
                {selectedPoint.city} {selectedPoint.zipCode}
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <CreditCardIcon
                  sx={{
                    pr: 0.25,
                  }}
                />
                {selectedPoint.payByCard
                  ? t("Possibility to pay by card")
                  : t("Not possible to pay by card at this pick-up point")}
              </Typography>
              {selectedPoint.wheelChairAccessible && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <AccessibleIcon
                    sx={{
                      pr: 0.25,
                    }}
                  />
                  {t("Wheelchair access")}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item lg={1} md={0}>
            {!isMobile && <Divider orientation="vertical" />}
          </Grid>
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              pt: isMobile ? "0!important" : 1,
              pb: isMobile ? 3 : 0,
            }}
          >
            {lessThanDesktop && <Divider sx={{ my: 1 }} />}

            {preferences &&
            preferences.showAvailabilityLabel &&
            !isPointAvailable(selectedPoint) ? (
              <Typography variant="h5" color="error">
                {t("Pick-up point not available")}
              </Typography>
            ) : (
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" fontWeight={700}>
                  {t("Opening hours")}
                </Typography>
                <ScheduleItem
                  dayLabel={t("Monday")}
                  scheduleValue={selectedPoint.mondaySchedule}
                ></ScheduleItem>
                <ScheduleItem
                  dayLabel={t("Tuesday")}
                  scheduleValue={selectedPoint.tuesdaySchedule}
                ></ScheduleItem>
                <ScheduleItem
                  dayLabel={t("Wednesday")}
                  scheduleValue={selectedPoint.wednesdaySchedule}
                ></ScheduleItem>
                <ScheduleItem
                  dayLabel={t("Thursday")}
                  scheduleValue={selectedPoint.thursdaySchedule}
                ></ScheduleItem>
                <ScheduleItem
                  dayLabel={t("Friday")}
                  scheduleValue={selectedPoint.fridaySchedule}
                ></ScheduleItem>
                <ScheduleItem
                  dayLabel={t("Saturday")}
                  scheduleValue={selectedPoint.saturdaySchedule}
                ></ScheduleItem>
                <ScheduleItem
                  dayLabel={t("Sunday")}
                  scheduleValue={selectedPoint.sundaySchedule}
                ></ScheduleItem>
              </Box>
            )}
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};
