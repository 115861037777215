import * as React from "react";
import { ICollectPoint, State } from "../../types";
import { setSelectedPoint } from "../../store/actions";
import { Dispatch } from "redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Skeleton,
  Theme,
  Divider,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import pointSkeleton from "./pointSkeleton.json";

type Props = {
  points: ICollectPoint[];
  selectedPoint: ICollectPoint | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiListItemSecondaryAction-root": {
        right: 4,
      },
    },
  })
);

const PointsList: React.FC<Props> = ({ points, selectedPoint }) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const selectPoint = React.useCallback(
    (selectedPoint: ICollectPoint) => dispatch(setSelectedPoint(selectedPoint)),
    [dispatch]
  );

  const isFetching = useSelector(
    (state: State) => state.isFetching,
    shallowEqual
  );

  const searchParam = useSelector(
    (state: State) => state.filters.searchParam,
    shallowEqual
  );

  const availablePoints: ICollectPoint[] | null = useSelector(
    (state: State) => state.availablePoints,
    shallowEqual
  );

  const memoizedList = React.useMemo(
    () =>
      (!isFetching ? points : Array(20).fill(pointSkeleton)).map(
        (point, index) => (
          <Box key={index}>
            <ListItem
              disablePadding
              secondaryAction={
                <Typography variant="subtitle2" color={"rgba(0, 0, 0, 0.6)"}>
                  {point?.distance ? `${point.distance}km` : ""}
                </Typography>
              }
              selected={
                selectedPoint && selectedPoint?.id === point?.id ? true : false
              }
              autoFocus={
                selectedPoint && selectedPoint?.id === point?.id ? true : false
              }
              onClick={() => {
                selectPoint(point);
                if (!searchParam) {
                  document.querySelector("#available-points")!.scrollTop = 0;
                }
              }}
            >
              <ListItemButton>
                <ListItemText
                  primary={
                    !isFetching ? (
                      point?.name
                    ) : (
                      <Skeleton
                        variant="text"
                        width={`${60 + Math.random() * 20}%`}
                      />
                    )
                  }
                  secondary={
                    !isFetching ? (
                      point?.address1
                    ) : (
                      <Skeleton
                        variant="text"
                        width={`${50 + Math.random() * 20}%`}
                      />
                    )
                  }
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Box>
        )
      ),
    [isFetching, points, selectedPoint, searchParam, availablePoints]
  );

  if (!isFetching && !points.length) return <></>;
  return (
    <List
      className={classes.root}
      component="nav"
      id="available-points"
      aria-label="main mailbox folders"
      sx={{
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      {memoizedList}
    </List>
  );
};

export default React.memo(PointsList);
