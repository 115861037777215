import * as React from "react";
import { useTranslation } from "react-i18next";
import { Filters, State } from "../../types";
import { GpsFixedRounded, Search } from "@mui/icons-material";
import { setFilters, setCenter } from "../../store/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { IconButton, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { LatLng } from "leaflet";
import { applyFilters } from "../../store/actions";
import { ThunkDispatch } from "redux-thunk";

export const PointsSearch: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const thunkDispatch: ThunkDispatch<any, any, any> = useDispatch();

  const userLocation = useSelector(
    (state: State) => state.userLocation,
    shallowEqual
  );
  const storeFilters: Filters = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const [typingTimeout, setTypingTimeout] =
    React.useState<NodeJS.Timeout | null>(null);

  const updateSearch = (event: { target: { value: string } }) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      const searchParam = event.target.value === "" ? null : event.target.value;
      dispatch(
        setFilters({
          ...storeFilters,
          searchParam,
        })
      );
      thunkDispatch(applyFilters());
    }, 1000);
    setTypingTimeout(newTypingTimeout);
  };

  const recenterToUser = React.useCallback(
    (userLocation: LatLng) => dispatch(setCenter(userLocation)),
    [dispatch]
  );

  return (
    <TextField
      size="medium"
      fullWidth
      placeholder={t("Search for a pick up point")}
      variant="outlined"
      focused={false}
      onChange={updateSearch.bind(this)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {userLocation ? (
              <IconButton
                aria-label="toggle"
                onClick={() => recenterToUser(userLocation)}
              >
                <GpsFixedRounded fontSize="small" color="inherit" />
              </IconButton>
            ) : null}
            <Search color="inherit" />
          </InputAdornment>
        ),
      }}
    />
  );
};
