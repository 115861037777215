import { Filters, ICollectPoint } from "../types";

const searchFilterFn = (search: string, point: ICollectPoint) => {
  let match = false;
  if (point.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
    match = true;
  }
  if (point.address1.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
    match = true;
  }
  if (point.address2.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
    match = true;
  }
  if ((point.street || "").toLowerCase().indexOf(search.toLowerCase()) !== -1) {
    match = true;
  }
  if (point.zipCode.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
    match = true;
  }
  if (point.city.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
    match = true;
  }

  if ((point.county || "").toLowerCase().indexOf(search.toLowerCase()) !== -1) {
    match = true;
  }
  if (
    (point.country || "").toLowerCase().indexOf(search.toLowerCase()) !== -1
  ) {
    match = true;
  }

  if (
    (point.countyCode || "").toLowerCase().indexOf(search.toLowerCase()) !== -1
  ) {
    match = true;
  }
  return match;
};

export const filterPoints = (filters: Filters, points: ICollectPoint[]) => {
  let items: ICollectPoint[] = points;

  if (filters.saturdayOpen) {
    items = items.filter((item) => item.saturdaySchedule);
  }
  if (filters.sundayOpen) {
    items = items.filter((item) => item.sundaySchedule);
  }
  if (filters.wheelChairAccessible) {
    items = items.filter((item) => item.wheelChairAccessible);
  }
  if (filters.availableCapacity) {
    items = items.filter((item) => item.availableCapacity);
  }
  if (filters.payByCard) {
    items = items.filter((item) => item.payByCard);
  }
  return items;
};

export const computeDistance = (from: L.LatLng, to: L.LatLng) => {
  const distance = (from.distanceTo(to) / 1000).toFixed(2);
  return `${distance}`;
};

export const sortPointsByDistance = (points: ICollectPoint[]) => {
  return points
    .sort((a, b) => (parseFloat(a.distance) > parseFloat(b.distance) ? -1 : 1))
    .reverse();
};
